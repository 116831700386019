import { css, Switch, Checkbox, FormControl, FormControlLabel } from '@mui/material'
import { memo } from 'react'
import { proxy, useSnapshot } from 'valtio'
import { flex } from '~/modules/AppLayout/FlexGridCss'
import {
  IndicatorEnum,
  JoinEnum,
  AvailableIndicator,
  useKabujukuResource,
} from '~/modules/screener/containers/useStockScreenerResource'

export const useKabujukuParamStore = proxy({
  join: 'union' as keyof typeof JoinEnum,
  indicators: {} as { [key in AvailableIndicator]: boolean },
})

export const Kabujuku = memo(function Kabujuku() {
  const kabujukuState = useSnapshot(useKabujukuParamStore)
  return (
    <div css={flex.v.default}>
      <div css={flex.h.crossCenter}>
        <p>AND</p>
        <Switch
          onChange={e => {
            useKabujukuParamStore.join = e.target.checked ? 'union' : 'intersection'
          }}
          checked={kabujukuState.join === 'union'}
        />
        <p>OR</p>
      </div>
      <div css={flex.wrap.default}>
        {Object.entries(IndicatorEnum).map(([k, v]) => (
          <FormControl key={k}>
            <FormControlLabel
              onChange={(e, checked) => {
                useKabujukuParamStore.indicators[k as AvailableIndicator] = checked
              }}
              value={kabujukuState.indicators[k as AvailableIndicator]}
              control={
                <Checkbox
                  color='primary'
                  defaultChecked={kabujukuState.indicators[k as AvailableIndicator]}
                />
              }
              label={v}
              labelPlacement='end'
            />
          </FormControl>
        ))}
      </div>
    </div>
  )
})

// TODO: 合併完之後移除
export const YourComponent = memo(function YourComponent() {
  const kabujukuParamState = useSnapshot(useKabujukuParamStore)
  const { join, indicators } = kabujukuParamState
  const selectedIndicators = Object.entries(indicators)
    .filter(([_, v]) => v)
    .map(([k, _]) => k) as AvailableIndicator[]

  const { data: symbols } = useKabujukuResource({
    date: '2022-07-06',
    join,
    indicators: selectedIndicators,
  })

  return (
    <div
      css={css`
        width: 360px;
      `}
    >
      <div>your other components...</div>
      <div>
        <Kabujuku />
      </div>
      {symbols?.map(s => (
        <p key={s}>{s}</p>
      ))}
    </div>
  )
})
