import { css } from '@emotion/react'

export const baseNoLinkCss = css`
  &,
  &:hover,
  &:active,
  &:visited {
    color: inherit;
  }
`
