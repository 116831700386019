import { isEmpty, mergeWith } from 'lodash'
import { useMemo, useState, useRef } from 'react'
import { useInterval } from 'react-use'
import { useIntradayPick } from '~/modules/screener/useDatePick'
import {
  AdvanceDeclineResourceData,
  useAdvanceDeclineResource,
} from '~/modules/screener/containers/useStockScreenerResource'
import { createContainer } from '~/modules/unstated-next-utils/createContainer'

export const useStockPriceChangeDistribution = () => {
  const [categories, setCategories] = useState<string[]>(['total'])
  const rawDate = useIntradayPick()
  const [date, setDate] = useState<string>()
  const [flag, setFlag] = useState<number>(0)
  const allCategoriesRef = useRef<string[]>()
  const dataRef = useRef<AdvanceDeclineResourceData[]>()
  const { data } = useAdvanceDeclineResource(date, flag)
  if (rawDate && !date) {
    setDate(rawDate)
  }

  // ref目前沒東西，且有後端資料，先assign值
  if ((!dataRef.current || dataRef.current?.length === 0) && data) {
    dataRef.current = data
    allCategoriesRef.current = data?.map(d => d.name)
  }

  // 有舊資料，但新資料為空的
  if (dataRef.current && dataRef.current.length > 0 && data) {
    dataRef.current = []
    allCategoriesRef.current = []
  }

  // 若新來的資料與過往不同，則重新assign
  if (!isEmpty(data) && data?.toString() !== dataRef.current?.toString()) {
    dataRef.current = data
    allCategoriesRef.current = data?.map(d => d.name)
  }

  const selectedSource = useMemo(() => {
    if (!dataRef?.current || dataRef?.current.length === 0) {
      return {}
    } else {
      if (categories.length === 1 && categories[0] === 'total') return dataRef.current[0].count
      else {
        const availableCategories = (dataRef?.current ?? [])
          .filter(datum => categories.indexOf(datum.name) !== -1)
          .map(datum => datum.count)

        return availableCategories.reduce((a, b) => mergeWith(a, b, customizer), {})
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories, dataRef.current])

  const vBarSource = Object.entries(selectedSource)
  /* 上漲家數 */
  const riseCount = dataRef.current
    ? Object.entries(selectedSource)
        .filter(([k, v]) => parseInt(k) > 0)
        .map(([k, v]) => v)
        .reduce((a, b) => a + b, 0)
    : 0
  /* 下跌家數 */
  const fallCount = dataRef.current
    ? Object.entries(selectedSource)
        .filter(([k, v]) => parseInt(k) < 0)
        .map(([k, v]) => v)
        .reduce((a, b) => a + b, 0)
    : 0

  /* 無變化家數 */
  const flatCount = dataRef.current
    ? Object.entries(selectedSource)
        .filter(([k, v]) => parseInt(k) === 0)
        .map(([k, v]) => v)
        .reduce((a, b) => a + b, 0)
    : 0

  const overallCount = fallCount + flatCount + riseCount

  const avg_change_percent = new Map(data?.map(d => [d.name, d.avg_change_percent]))

  // call data every 60 seconds
  useInterval(() => {
    setFlag(flag + 1)
  }, 60000)

  return {
    state: {
      riseCount,
      fallCount,
      flatCount,
      overallCount,
      allCategories: allCategoriesRef.current,
      categories,
      avg_change_percent,
      vBar: vBarSource,
      date,
    },
    acts: { setCategories, setDate },
  }
}

const customizer = (objValue: AdvanceDeclineResourceData, srcValue: AdvanceDeclineResourceData) => {
  if (typeof objValue === 'number') {
    return objValue + srcValue
  }
}

export const useStockPriceChangeDistributionState = createContainer(useStockPriceChangeDistribution)
